// You can use CONSTANTS.js file for below definitions of constants and import here.
export const SIZE_CHANGED = "SIZE_CHANGED";
export const MENU_COLLAPSED_CHANGED = "MENU_COLLAPSED_CHANGED";
export const CURRENT_ROUTE = "CURRENT_ROOT";

export const UPDATE_OVERVIEW_INFO = "UPDATE_OVERVIEW_INFO";

export const UPDATE_VENDOR_LIST = "UPDATE_VENDOR_LIST";
export const UPDATE_SUPPLIER_LIST = "UPDATE_SUPPLIER_LIST";
export const UPDATE_USER_LIST = "UPDATE_USER_LIST";

export const ChangeSize = (payload) => ({
  type: SIZE_CHANGED,
  payload,
});

export const ChangeMenuCollapsed = (payload) => ({
  type: MENU_COLLAPSED_CHANGED,
  payload,
});

export const UpdateCurrentRoot = (payload) => ({
  type: CURRENT_ROUTE,
  payload,
});

export const UpdateOverviewInfo = (payload) => ({
  type: UPDATE_OVERVIEW_INFO,
  payload: payload,
});

export const UpdateVendorList = (payload) => ({
  type: UPDATE_VENDOR_LIST,
  payload: payload,
});
export const UpdateSupplierList = (payload) => ({
  type: UPDATE_SUPPLIER_LIST,
  payload: payload,
});
export const UpdateUserList = (payload) => ({
  type: UPDATE_USER_LIST,
  payload: payload,
});
