// @flow strict

import { message } from "antd";

import { REGISTER_WITH } from "config/CONSTANTS";
import { useAuth } from "navigation/Auth/ProvideAuth";
import { ROUTE_HOME } from "navigation/CONSTANTS";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, withRouter } from "react-router";
import { UpdateCurrentRoot } from "redux/actions/appActions";
import {
  setFromUrl,
  updateSubscription,
  updateUser,
} from "redux/actions/authActions";
import InputPage from "./InputPage";

const LOGIN_PAGES = {
  InputPage: "InputPage",
  ActivationCodePage: "ActivationCodePage",
  InputLocationPage: "InputLocationPage",
};
const pageList = [
  LOGIN_PAGES.InputPage,
  LOGIN_PAGES.ActivationCodePage,
  LOGIN_PAGES.InputLocationPage,
];
function LoginPage(props) {
  const authDataStore = useSelector((x) => x.authDataStore);
  const { detail_token } = useParams();
  const auth = useAuth();
  const dispatch = useDispatch();
  const [currentPage, setCurrentpage] = useState(0);
  const [userDetail, setUserDetail] = useState({
    register_with: REGISTER_WITH.email,
    canModify: true,
  });
  const [isBusy, setIsBusy] = useState(false);
  useEffect(() => {
    dispatch(updateUser({ selAgent: "" }));
    if (detail_token === undefined) return;
    const detail = atob(detail_token);
    const array = detail.split("/");
    if (array.length >= 3) {
      setUserDetail({
        ...userDetail,
        canModify: false,
        email: array[1],
        agent_accept_code: array[2],
      });
    }
  }, [detail_token]);

  useEffect(() => {
    dispatch(updateUser({ token: undefined }));
  }, []);

  const onClickLogin = async () => {
    setIsBusy(true);
    auth
      .signin(userDetail)
      .then((res) => {
        try {
          const { token } = res;
          if (res.user === undefined || res.token === undefined) {
            return;
          }
          dispatch(updateUser(res));
          const fromUrl = authDataStore.fromUrl;
          setTimeout(() => {
            if (fromUrl == undefined || fromUrl.pathname == undefined) {
              props.history.replace(ROUTE_HOME);
            } else {
              dispatch(setFromUrl(undefined));
              props.history.replace(fromUrl.pathname);
            }
          }, 500);
        } catch (error) {
          message.error("Login Failed.");
          setIsBusy(false);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Login Failed. " + err);
      });
  };

  return (
    <div style={{ color: "var(--blackColor)" }}>
      <div
        style={{
          display:
            pageList[currentPage] === LOGIN_PAGES.InputPage ? "block" : "none",
        }}
      >
        <InputPage
          onClickLogin={onClickLogin}
          userDetail={userDetail}
          setUserDetail={setUserDetail}
          isBusy={isBusy}
        />
      </div>
    </div>
  );
}

export default withRouter(LoginPage);
