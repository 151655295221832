import { Col, Input, message, Modal, Row, Select } from "antd";
import InputLocation from "components/InputWidgets/InputLocation/InputLocation";
import React, { useState } from "react";
import { useEffect } from "react";
import { confirmable } from "react-confirm";
import { getCoordinateFromPlaceID } from "services/commonService";
import "./EmailInfoDlg.css";
const { TextArea } = Input;
const { Option } = Select;

const EmailInfoDlg = (props) => {
  const { show, proceed } = props;
  const [location, setLocation] = useState(null);

  const [messageDetail, setMessageDetail] = useState({
    message: "",
    email_subject: "",
    rel_url: "",
    is_email: true,
    user_count: undefined,
    place_id: undefined,
  });
  useEffect(() => {
    if (location == "" || location == null) return;
    getCoordinateFromPlaceID(location.value)
      .then((res) => {
        setMessageDetail({
          ...messageDetail,
          user_count: res["user_count"],
          place_id: location["value"],
        });
      })
      .catch((err) => {
        setMessageDetail({
          ...messageDetail,
          user_count: undefined,
          place_id: undefined,
        });
      });
  }, [location]);
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      className="radial-modal"
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={true}
      title={"Message Detail"}
      onOk={() => {
        if (messageDetail.message == "") {
          message.error("Content is empty.");
          return;
        }

        proceed(messageDetail);
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <div>Choose users by location</div>
        <div>
          <InputLocation location={location} setLocation={setLocation} />
        </div>
        {messageDetail.user_count != undefined && (
          <div style={{ color: "var(--blueColor)", marginTop: 10 }}>
            Total users: {messageDetail.user_count}
          </div>
        )}
      </div>
      <Row align={"middle"} style={{ marginBottom: 20 }} gutter={16}>
        <Col>
          <div>Send message via</div>
        </Col>
        <Col>
          <Select
            defaultValue={true}
            style={{ width: 120 }}
            onChange={(v) => {
              setMessageDetail({
                ...messageDetail,
                is_email: v,
              });
            }}
          >
            <Option value={true}>Email</Option>
            <Option value={false}>SMS</Option>
          </Select>
        </Col>
      </Row>
      {messageDetail.is_email == true && (
        <div>
          <div>Subject(optional)</div>
          <Input
            placeholder={"Input Email Subject (From SeeMeSave)"}
            onChange={(e) =>
              setMessageDetail({
                ...messageDetail,
                email_subject: e.target.value,
              })
            }
          />
        </div>
      )}

      <div style={{ marginTop: 20 }}>Content(*)</div>
      <TextArea
        rows={5}
        maxLength={500}
        showCount
        placeholder={"Input message you want to send."}
        onChange={(e) =>
          setMessageDetail({ ...messageDetail, message: e.target.value })
        }
      />
      {messageDetail.is_email == true && (
        <div>
          <div style={{ marginTop: 20 }}>Related Link(optional)</div>
          <Input
            placeholder={"Input SeeMeSave Link. (https://seemesave.com)"}
            onChange={(e) =>
              setMessageDetail({ ...messageDetail, rel_url: e.target.value })
            }
          />
        </div>
      )}
    </Modal>
  );
};

export default confirmable(EmailInfoDlg);
