import { message, Spin } from "antd";
import { showEmailInfoDlg } from "components/EmailInfoDlg/showEmailInfoDlg";
import React, { useState, useEffect } from "react";
import {
  apiGetPeriodUserList,
  apiSendUserEmail,
  apiSendUserSMS,
  getUserList,
} from "services/userServices";
import "./UserPage.css";
import blankAvatar from "../../assets/images/avatar_blank.png";
import CountDataViewer from "components/CountDataViewer/CoundDataViewer";
const init_page_size = 10;

const UserPage = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    readPeriodData();
  }, []);
  const readPeriodData = (start_date = "", end_date = "") => {
    setIsBusy(true);
    apiGetPeriodUserList(start_date, end_date)
      .then((res) => {
        // console.log("res", res);
        setIsBusy(false);
        setDataList(res.dataList);
        
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Error:", err);
      });
  };
  /*
  const [userList, setUserList] = useState();
  const [pagination, setPagination] = useState();
  const [sorter, setSorter] = useState();
  
  useEffect(() => {
    getUserList("", "", 0, init_page_size).then((res) => {
      setUserList([...res.data]);
      setPagination({
        current: 1,
        pageSize: init_page_size,
        total: res.totalCount,
      });
    });
  }, []);
  const columns = [
    { title: "No", dataIndex: "order", width: 50 },

    {
      title: "Avatar",
      dataIndex: "image_url",
      width: 70,
      render: (image_url) => {
        const url =
          image_url == null || image_url == "" ? blankAvatar : image_url;

        return (
          <img
            style={{ width: "100%" }}
            src={url}
            style={{ width: 50, height: 50, borderRadius: "50%" }}
          />
        );
      },
    },
    {
      title: "First Name",
      width: 120,
      dataIndex: "first_name",
      sorter: true,
    },
    {
      title: "Last Name",
      width: 120,
      dataIndex: "last_name",
      sorter: true,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "is_trader",
      width: 100,
      sorter: true,
      render: (is_trader) => {
        let userType = "Consumer";
        if (is_trader) userType = "Trader";
        return userType;
      },
    },
    {
      title: "Registered",
      dataIndex: "created",
      width: 110,
      sorter: true,
    },
  ];
  const readUserListData = (keyword, newPagination, filters, newSorter) => {
    setSorter(newSorter);
    const sorter =
      newSorter == undefined
        ? ""
        : newSorter.order == undefined
        ? ""
        : `${newSorter.order == "ascend" ? "" : "-"}` + "" + newSorter.field;

    setPagination(newPagination);
    setSorter(newSorter);
    setIsBusy(true);
    getUserList(
      keyword,
      sorter,
      (newPagination.current - 1) * newPagination.pageSize,
      newPagination.pageSize
    )
      .then((res) => {
        setIsBusy(false);
        setUserList([...res.data]);
        setPagination({ ...newPagination, total: res.totalCount });
      })
      .catch((err) => {
        setIsBusy(false);
      });
  };
*/
  const SendMessage = async (str_dest) => {
    const emailInfo = await showEmailInfoDlg();
    if (emailInfo == null) return;
    const { is_email } = emailInfo;
    if (is_email) {
      apiSendUserEmail({
        ...emailInfo,
        user_list: str_dest,
      }).then((res) => {
        message.success("Email has been sent successfully.");
      });
    } else {
      apiSendUserSMS({
        ...emailInfo,
        user_list: str_dest,
      }).then((res) => {
        message.success("SMS has been sent successfully.");
      });
    }
  };
  if (dataList == undefined) {
    return (
      <Spin>
        <div style={{ height: 300, textAlign: "center", paddingTop: 150 }}>
          Loading Data
        </div>
      </Spin>
    );
  }

  return (
    <div>
      <CountDataViewer
        dataList={[
          ...dataList.map((x) => {
            return {
              time: x.created_date,
              value: x.activeCount,
              type: "Active Users",
            };
          }),
          ...dataList.map((x) => {
            return {
              time: x.created_date,
              value: x.count - x.activeCount,
              type: "Inactive Users",
            };
          }),
        ]}
        totalData={dataList.map((x) => ({
          time: x.created_date,
          Total: x.count,
        }))}
        isBusy={isBusy}
        SendMessage={SendMessage}
        readPeriodData={readPeriodData}
      />
    </div>
  );
};

export default UserPage;
