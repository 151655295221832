import Icon, {
  LogoutOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ReactComponent as dashboardIcon } from "../assets/icons/ic_dashboard.svg";
import { ReactComponent as storeIcon } from "../assets/icons/ic_store.svg";
import { ReactComponent as analyticsIcon } from "../assets/icons/ic_analytics.svg";
import { ReactComponent as promotionIcon } from "../assets/icons/ic_promotion.svg";
import { ReactComponent as bestdealIcon } from "../assets/icons/ic_bestdeal.svg";
import { ReactComponent as productIcon } from "../assets/icons/ic_product.svg";
import { ReactComponent as postIcon } from "../assets/icons/ic_post.svg";
import { ReactComponent as followerIcon } from "../assets/icons/ic_follower.svg";
import { ReactComponent as subscriptionIcon } from "../assets/icons/ic_subscription.svg";
import { ReactComponent as agentIcon } from "../assets/icons/ic_agent.svg";

export const ROOT = "/";

export const ROUTE_HOME = "/home";
export const ROUTE_LOGIN = "/login";
export const ROUTE_USER = "/user";
export const ROUTE_VENDOR = "/vendor";
export const ROUTE_SUPPLIER = "/supplier";

export const MENU_ITEMS = [
  {
    route: ROUTE_HOME,
    icon: <Icon component={dashboardIcon} className="menu-item-icon" />,
    title: "Overview",
    type: "sidebar",
  },
  {
    route: ROUTE_USER,
    icon: <Icon component={followerIcon} className="menu-item-icon" />,
    title: "Users",
    type: "sidebar",
  },
  {
    route: ROUTE_SUPPLIER,
    icon: <Icon component={followerIcon} className="menu-item-icon" />,
    title: "Suppliers",
    type: "sidebar",
  },
  {
    route: ROUTE_VENDOR,
    icon: <Icon component={followerIcon} className="menu-item-icon" />,
    title: "Vendors",
    type: "sidebar",
  },
  // { route: ROUTE_USER_PROFILE, icon: UserOutlined, title: "User Profile", type:"option" },
  // { route: ROUTE_VENDOR_PROFILE, icon: ShoppingCartOutlined, title: "Vendor Profile", type:"option" },
];
