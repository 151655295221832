import { axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import {
  urlGetPeriodUserList,
  urlGetUserList,
  urlUserEmailSend,
  urlUserSMSSend,
} from "./CONSTANTS";

export const getUserList = (
  keyword = "",
  sorter = "-created",
  offset = 0,
  page_size = 20
) => {
  const url = urlGetUserList;
  const param = {
    keyword: keyword,
    sorter: sorter,
    offset: offset,
    page_size: page_size,
  };
  return new Promise((resolve, reject) => {
    axiosGet(url, param, "getUserList")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetPeriodUserList = (start_date = "", end_date = "") => {
  const url = urlGetPeriodUserList;
  const param = {
    start_date: start_date,
    end_date: end_date,
  };
  return new Promise((resolve, reject) => {
    axiosGet(url, param, "getPeriodUserList")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiSendUserEmail = (param) => {
  const url = urlUserEmailSend;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "apiSendUserEmail")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSendUserSMS = (param) => {
  const url = urlUserSMSSend;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "apiSendUserSMS")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
