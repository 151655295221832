import { SearchOutlined } from "@ant-design/icons";
import { Input, Row, Col, Table, Spin, Calendar, message } from "antd";
import React, { useState } from "react";
import "./CountDataViewer.css";
import { DualAxes, G2 } from "@ant-design/charts";
import moment from "moment";

const CountDataViewer = (props) => {
  const { dataList, isBusy, SendMessage, readPeriodData, totalData } = props;
  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  var registerTheme = G2.registerTheme;
  registerTheme("custom-theme", {
    colors10: ["#43AAE1", "#FF0000", "#1E1E1E"],
  });

  var config = {
    height: 500,
    data: [dataList, totalData == undefined ? [] : totalData],
    xField: "time",
    yField: ["value", "Total"],
    limitInPlot: false,
    padding: [20, 20, 80, 30],
    slider: { height: 50 },
    meta: { time: { sync: false } },

    geometryOptions: [
      {
        geometry: "column",
        isStack: true,
        seriesField: "type",
      },
      {
        geometry: "line",
        lineStyle: { lineWidth: 0.5 },
        smooth: true,
      },
    ],
    animation: false,
    theme: "custom-theme",
  };

  const onClickSendMessageAll = () => {
    SendMessage("");
  };
  const onClickReadData = () => {
    if ((startDate != "" || endDate != "") && startDate >= endDate) {
      message.error("From Date should be less than To Date.");
      return;
    }
    readPeriodData(startDate, endDate);
  };
  const activeCount = dataList
    .filter((x) => x.type == "Active Users")
    .reduce((accumulator, object) => {
      return accumulator + object.value;
    }, 0);
  const inActiveCount = dataList
    .filter((x) => x.type == "Inactive Users")
    .reduce((accumulator, object) => {
      return accumulator + object.value;
    }, 0);
  return (
    <div>
      <Spin spinning={isBusy}>
        <Row justify={"space-between"}>
          {/* <Col xs={24} md={12}>
            <Input
              prefix={<SearchOutlined />}
              className="data-search-input"
              placeholder={"Search by keyword"}
              type={"search"}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              onPressEnter={(e) => {
                readData(e.target.value, pagination, "", sorter);
              }}
            />
          </Col>
          <Col xs={24} md={12} style={{ textAlign: "right" }}>
            <span
              className={`${
                selKeys.length > 0
                  ? "send-email-button custom-button"
                  : "send-email-button send-email-button-disable"
              }`}
              onClick={onClickSendMessageSelected}
            >
              Send Message To Current
            </span>
            <span
              className={"send-email-button custom-button"}
              style={{ marginLeft: 30 }}
              onClick={onClickSendMessageAll}
            >
              Send Message To All
            </span>
          </Col> */}
          <Col xs={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <div>From Date</div>
                <div style={{ height: 150 }}>
                  <Calendar
                    style={{ height: 150 }}
                    fullscreen={false}
                    onSelect={(v) => {
                      setStartDate(v.format("YYYY-MM-DD"));
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div>To Date</div>
                <Calendar
                  fullscreen={false}
                  onSelect={(v) => {
                    setEndDate(v.format("YYYY-MM-DD"));
                  }}
                />
              </Col>
              <Col xs={24}></Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <div
                  className={"send-email-button custom-button"}
                  style={{ textAlign: "center" }}
                  onClick={onClickReadData}
                >
                  Read Data
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div
                  className={"send-email-button custom-button"}
                  style={{ textAlign: "center" }}
                  onClick={onClickSendMessageAll}
                >
                  Send Message To All
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            {dataList.length == 0 ? (
              <div style={{ textAlign: "center", marginTop: 20 }}>No Data</div>
            ) : (
              <div>
                <div
                  style={{
                    margin: "10px 0px",
                    fontFamily: "mediumFont",
                    fontSize: 16,
                  }}
                >
                  {startDate} ~ {endDate}
                </div>
                <div>
                  <Row gutter={16}>
                    <Col>Total: {activeCount + inActiveCount} </Col>
                    <Col>Active: {activeCount}</Col>
                    <Col>Inactive: {inActiveCount}</Col>
                  </Row>
                </div>
                <DualAxes {...config} />
              </div>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default CountDataViewer;
