import { message, Spin } from "antd";
import CountDataViewer from "components/CountDataViewer/CoundDataViewer";
import { showEmailInfoDlg } from "components/EmailInfoDlg/showEmailInfoDlg";

import React, { useEffect, useState } from "react";
import {
  apiGetPeriodVendorList,
  apiSendVendorEmail,
  apiSendVendorSMS,
  getVendorList,
} from "services/vendorService";

import "./VendorPage.css";
const init_page_size = 10;

const VendorPage = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    readPeriodData();
  }, []);
  const readPeriodData = (start_date = "", end_date = "") => {
    setIsBusy(true);
    apiGetPeriodVendorList(start_date, end_date)
      .then((res) => {
        console.log("res", res);
        setIsBusy(false);
        setDataList(res.dataList);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Error:", err);
      });
  };
  /*
  const [vendorList, setVendorList] = useState();
  const [pagination, setPagination] = useState();
  const [sorter, setSorter] = useState();
  useEffect(() => {
    getVendorList("", "", 0, init_page_size).then((res) => {
      setVendorList([...res.data]);
      setPagination({
        current: 1,
        pageSize: init_page_size,
        total: res.totalCount,
      });
    });
  }, []);
  const columns = [
    { title: "No", dataIndex: "order", width: 50 },
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Logo",
      dataIndex: "logo",
      width: 100,
      render: (logo) => {
        if (logo == undefined || logo == "") {
          return <div />;
        }
        return <img style={{ width: "100%" }} src={logo} />;
      },
    },

    {
      title: "MainUser",
      dataIndex: "MainUser",
    },
    {
      title: "Follower",
      dataIndex: "FollowerCount",
      width: 70,
    },
    {
      title: "Subscription",
      dataIndex: "Subscription",
      width: 100,
      sorter: true,
    },
    {
      title: "Created",
      dataIndex: "created",
      width: 110,
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 60,
    },
  ];
*/
  const SendMessage = async (str_dest) => {
    const emailInfo = await showEmailInfoDlg();
    if (emailInfo == null) return;
    const { is_email } = emailInfo;
    if (is_email) {
      apiSendVendorEmail({
        ...emailInfo,
        vendor_list: str_dest,
      }).then((res) => {
        message.success("Email has been sent successfully.");
      });
    } else {
      apiSendVendorSMS({
        ...emailInfo,
        vendor_list: str_dest,
      }).then((res) => {
        message.success("Email has been sent successfully.");
      });
    }
  };
  return (
    <div>
      <CountDataViewer
        dataList={[
          ...dataList.map((x) => {
            return {
              time: x.created_date,
              value: x.count - x.inActiveCount,
              type: "Active Vendors",
            };
          }),
          ...dataList.map((x) => {
            return {
              time: x.created_date,
              value: x.inActiveCount,
              type: "Inactive Vendors",
            };
          }),
        ]}
        totalData={dataList.map((x) => ({
          time: x.created_date,
          Total: x.count,
        }))}
        isBusy={isBusy}
        SendMessage={SendMessage}
        readPeriodData={readPeriodData}
      />
    </div>
  );
};

export default VendorPage;
