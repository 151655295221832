// @flow strict

import { Col, Input, message, Row, Select, Spin } from "antd";
import React, { useState } from "react";
import "./InputLocation.css";
import AsyncSelect from "react-select/async";
import { getAddresses, getCoordinateFromPlaceID } from "services/commonService";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
};
function InputLocation(props) {
  const { location, setLocation } = props;
  const { address } = props;
  const [inputValue, setInputValue] = useState("");
  const promiseOptions = (keyword) =>
    new Promise((resolve, reject) => {
      if (keyword === "" || keyword === undefined) {
        reject();
      } else {
        getAddresses({ keyword: keyword })
          .then((res) => {
            resolve(
              res.map((info) => ({
                value: info.place_id,
                label: info.description,
              }))
            );
          })
          .catch((err) => reject());
      }
    });

  return (
    <div className="input-location-widget">
      <Row align={"middle"}>
        <Col flex={"auto"}>
          <AsyncSelect
            cacheOptions
            styles={customStyles}
            defaultOptions
            loadOptions={promiseOptions}
            inputValue={inputValue}
            placeholder={
              address === undefined || address === null
                ? "Your address..."
                : address
            }
            onInputChange={(e) => {
              setInputValue(e);
            }}
            onChange={(info) => {
              // getCoordinateFromPlaceID
              setLocation(info)
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default InputLocation;
