import React from "react";
import "./MainLayout.css";
import { useHistory } from "react-router-dom";
import SidebarLayout from "layouts/SidebarLayout/SidebarLayout";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
const MainLayout = (props) => {
  return (
    <SidebarLayout>
      <TopbarLayout>
        <div className="main-page">{props.children}</div>
      </TopbarLayout>
    </SidebarLayout>
  );
};

export default MainLayout;
