import { axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import {
  urlGetPeriodVendorList,
  urlGetVendorList,
  urlVendorEmailSend,
  urlVendorSMSSend,
} from "./CONSTANTS";

export const getVendorList = (
  keyword = "",
  sorter = "-created",
  offset = 0,
  page_size = 20
) => {
  const url = urlGetVendorList;
  const param = {
    keyword: keyword,
    sorter: sorter,
    offset: offset,
    page_size: page_size,
  };
  return new Promise((resolve, reject) => {
    axiosGet(url, param, "getVendorlist")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiGetPeriodVendorList = (start_date = "", end_date = "") => {
  const url = urlGetPeriodVendorList;
  const param = {
    start_date: start_date,
    end_date: end_date,
  };
  return new Promise((resolve, reject) => {
    axiosGet(url, param, "apiGetPeriodVendorList")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSendVendorEmail = (param) => {
  const url = urlVendorEmailSend;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "apiSendVendorEmail")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSendVendorSMS = (param) => {
  const url = urlVendorSMSSend;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "apiSendVendorSMS")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
