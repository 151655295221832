import { axiosGet } from "./ajaxServices";
import { urlGetOverviewInfo } from "./CONSTANTS";



export const apiGetOverviewInfo = () => {
  const url = urlGetOverviewInfo;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, "getOverviewINfo")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};