export const GET_ALL_USERS = () => `/data/users`; ////// will remove soon
export const GET_USER_DETAILS = (id) => `/data/user`; ////// will remove soon

// export const BASE_URL = "http://192.168.0.57:8000";
// export const BASE_URL = "https://test.admin.seemesave.com";
export const BASE_URL = "https://live.v5.admin.seemesave.com";

export const urlUserLogin = BASE_URL + "/marketing_api/auth/";

export const urlGetOverviewInfo = BASE_URL + "/marketing_api/home/";

export const urlGetVendorList = BASE_URL + "/marketing_api/vendor/";
export const urlGetPeriodVendorList = BASE_URL + "/marketing_api/vendor/count/";
export const urlVendorEmailSend = BASE_URL + "/marketing_api/vendor/email/";
export const urlVendorSMSSend = BASE_URL + "/marketing_api/vendor/send-sms/";

export const urlGetSupplierList = BASE_URL + "/marketing_api/supplier/";
export const urlGetPeriodSupplierList =
  BASE_URL + "/marketing_api/supplier/count/";
export const urlSupplierEmailSend = BASE_URL + "/marketing_api/supplier/email/";
export const urlSupplierSMSSend =
  BASE_URL + "/marketing_api/supplier/send-sms/";

export const urlGetUserList = BASE_URL + "/marketing_api/user/";
export const urlGetPeriodUserList = BASE_URL + "/marketing_api/user/count/";
export const urlUserEmailSend = BASE_URL + "/marketing_api/user/send-email/";
export const urlUserSMSSend = BASE_URL + "/marketing_api/user/send-sms/";

export const urlGetRegions = BASE_URL + "/marketing_api/user/get-region/";
export const urlGetPlaceById = BASE_URL + "/marketing_api/user/get-place-by-id/";
