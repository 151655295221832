import { axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import {
  urlGetPeriodSupplierList,
  urlGetSupplierList,
  urlSupplierEmailSend,
  urlSupplierSMSSend,
} from "./CONSTANTS";

export const getSupplierList = (
  keyword = "",
  sorter = "-created",
  offset = 0,
  page_size = 20
) => {
  const url = urlGetSupplierList;
  const param = {
    keyword: keyword,
    sorter: sorter,
    offset: offset,
    page_size: page_size,
  };
  return new Promise((resolve, reject) => {
    axiosGet(url, param, "getSupplierlist")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiGetPeriodSupplierList = (start_date = "", end_date = "") => {
  const url = urlGetPeriodSupplierList;
  const param = {
    start_date: start_date,
    end_date: end_date,
  };
  return new Promise((resolve, reject) => {
    axiosGet(url, param, "apiGetPeriodSupplierList")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSendSupplierEmail = (param) => {
  const url = urlSupplierEmailSend;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "sendSupplierEmail")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSendSupplierSMS = (param) => {
  const url = urlSupplierSMSSend;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "apiSendSupplierSMS")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
