// @flow strict

import { Divider, Spin, Row, Col, message } from "antd";
import InfoPaneWidget from "components/InfoPaneWidget/InfoPaneWidget";
import { ROUTE_SUPPLIER, ROUTE_USER, ROUTE_VENDOR } from "navigation/CONSTANTS";
import React from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateOverviewInfo } from "redux/actions/appActions";
import { apiGetOverviewInfo } from "services/homeService";

import "./HomePage.css";
const OverViewInfo = (props) => {
  const history = useHistory();
  const { title, info, route } = props;
  const {
    totalCount,
    activeCount,
    weeklyRegister,
    monthlyRegister,
    lastRegister,
  } = info;
  return (
    <div>
      <div className="overview-info-title">{title}</div>
      <Divider style={{ margin: 0 }} />
      <div style={{ marginLeft: 30 }}>
        
        <div style={{ marginTop: 30 }}>
          <span className="overview-info-item-title">Inactive Count: </span>
          <span className="overview-info-item-value">
            {totalCount - activeCount}
          </span>
        </div>
        <div style={{ marginTop: 10 }}>
          <span className="overview-info-item-title">Weekly Register: </span>
          <span className="overview-info-item-value">{weeklyRegister}</span>
        </div>
        <div style={{ marginTop: 10 }}>
          <span className="overview-info-item-title">Monthly Register: </span>
          <span className="overview-info-item-value">{monthlyRegister}</span>
        </div>
        <div style={{ marginTop: 10 }}>
          <span className="overview-info-item-title">Last Register: </span>
          <span className="overview-info-item-value">
            {lastRegister.substring(0, 10)}
          </span>
        </div>
      </div>
      <div style={{ marginTop: 50, textAlign: "center", marginBottom: 50 }}>
        <span
          className={"overview-info-button custom-button"}
          onClick={() => {
            history.push(route);
          }}
        >
          See Detail
        </span>
      </div>
    </div>
  );
};
function HomePage(props) {
  const appDataStore = useSelector((x) => x.appDataStore);
  const dispatch = useDispatch();
  useEffect(() => {
    apiGetOverviewInfo()
      .then((res) => {
        dispatch(UpdateOverviewInfo(res));
      })
      .catch((err) => {
        message.error("Error has been occurred. Refresh please.");
      });
  }, []);

  const { overviewInfo } = appDataStore;
  if (overviewInfo == undefined) {
    return (
      <Spin>
        <div style={{ height: 300, textAlign: "center", paddingTop: 150 }}>
          Loading Data
        </div>
      </Spin>
    );
  }

  const { userInfo, supplierInfo, vendorInfo } = overviewInfo;

  // return this.reduce((a, b) => a + (b[key] || 0), 0);
  return (
    <div>
      <Row gutter={[32, 32]} justify={"space-around"}>
        <Col xs={24} sm={12} xl={8}>
          <InfoPaneWidget>
            <OverViewInfo
              title={"User Information"}
              info={userInfo}
              route={ROUTE_USER}
            />
          </InfoPaneWidget>
        </Col>
        <Col xs={24} sm={12} xl={8}>
          <InfoPaneWidget>
            <OverViewInfo
              title={"Supplier Information"}
              info={supplierInfo}
              route={ROUTE_SUPPLIER}
            />
          </InfoPaneWidget>
        </Col>
        <Col xs={24} sm={12} xl={8}>
          <InfoPaneWidget>
            <OverViewInfo
              title={"Vendor Information"}
              info={vendorInfo}
              route={ROUTE_VENDOR}
            />
          </InfoPaneWidget>
        </Col>
      </Row>

      <div style={{ marginTop: 50 }} />
    </div>
  );
}

export default HomePage;
