import {
  MENU_COLLAPSED_CHANGED,
  SIZE_CHANGED,
  CURRENT_ROUTE,
  UPDATE_VENDOR_LIST,
  UPDATE_SUPPLIER_LIST,
  UPDATE_USER_LIST,
  UPDATE_OVERVIEW_INFO,
} from "redux/actions/appActions";

const initialState = {
  width: 1024,
  height: 768,
  collapsed: false,
  currentRoute: "/home",
  vendorList: undefined,
  supplierList: undefined,
  userList: undefined,
  overviewInfo: undefined,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIZE_CHANGED:
      return {
        ...state,
        ...action.payload,
      };
    case MENU_COLLAPSED_CHANGED:
      return {
        ...state,
        ...action.payload,
      };
    case CURRENT_ROUTE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_OVERVIEW_INFO:
      return {
        ...state,
        overviewInfo: { ...action.payload },
      };
    case UPDATE_VENDOR_LIST:
      return {
        ...state,
        vendorList: [...action.payload],
      };
    case UPDATE_SUPPLIER_LIST:
      return {
        ...state,
        supplierList: [...action.payload],
      };
    case UPDATE_USER_LIST:
      return {
        ...state,
        userList: [...action.payload],
      };
    default:
      return state;
  }
};
